import { Link } from '@components/Link'
import styled, { themeGet } from '@style'

const MenuSubItem = styled(Link)`
  width: 100%;
  color: ${themeGet('colors.white')};
  font-size: ${themeGet('fontSizes.1')};
  font-family: ${themeGet('fonts.sans')};
  font-weight: ${themeGet('fontWeights.normal')};
  text-decoration: none;
  padding-top: ${themeGet('space.2')};
  padding-bottom: ${themeGet('space.2')};
  padding-left: ${themeGet('space.5')};
  padding-right: ${themeGet('space.7')};
  text-align: right;
  margin-top: ${themeGet('space.1')};

  &.mobile-menu--active {
    font-style: normal;
    font-weight: inherit;
    position: relative;
    /* stylelint-disable */
    background-image: linear-gradient(
      90deg,
      ${themeGet('colors.blue.600')} 0%,
      ${themeGet('colors.blue.700')} 100%
    );
    /* stylelint-enable */
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 0.4em 100%;
  }
`

export { MenuSubItem }
