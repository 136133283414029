/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// import { useStaticQuery, graphql } from 'gatsby'

import { metaImages } from './metaImages'

function SEO({ metadata, breadcrumbs, article }) {
  // eslint-disable-next-line no-use-before-define
  // const { site } = useStaticQuery(query)
  // const { buildTime } = site

  // All schema.org data that is associated with the entire organization
  const organizationSchema = {
    '@context': 'http://schema.org',
    '@type': 'Corporation',
    '@id': 'https://company.science-and-leadership.com',
    name: 'Science & Leadership',
    alternateName: 'Notebaert Consulting',
    description: 'We bring science to business.',
    legalName: 'One Step Ahead – Notebaert Consulting',
    email: 'info@science-and-leadership.com',
    url: 'https://www.science-and-leadership.com',
    logo: metaImages.logo,
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Louis-Pasteur-Straße 74',
      postalCode: '60439',
      addressLocality: 'Frankfurt am Main',
      addressRegion: 'Hessen',
      addressCountry: 'DE',
    },
    sameAs: [
      `https://www.linkedin.com/in/karolien-notebaert-a7510b8/`,
      `https://twitter.com/karonotebaert`,
    ],
  }

  let breadcrumbsSchema = {}
  if (breadcrumbs.length) {
    const listElements = breadcrumbs.map(crumb => ({
      '@type': 'ListItem',
      position: crumb.position,
      name: crumb.position,
      item: crumb.url,
    }))

    breadcrumbsSchema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: listElements,
    }
  }

  let articleSchema = {}
  if (article) {
    articleSchema = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: article.author,
      },
      datePublished: article.datePublished,
      dateModified: article.dateModified,
      headline: article.headline,
    }
  }

  // All schemas combined (organization, blog post, etc.)
  const schema = {
    ...organizationSchema,
    ...breadcrumbsSchema,
    ...articleSchema,
  }

  const twitter = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: metadata.title },
    {
      name: 'twitter:description',
      content: metadata.description,
    },
    {
      name: 'twitter:site',
      content: '@karonotebaert',
    },
    {
      name: 'twitter:creator',
      content: '@karonotebaert',
    },
    {
      name: 'twitter:image',
      content: metadata.image ? metaImages[metadata.image] : metaImages.default,
    },
  ]

  const openGraph = [
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: metadata.title },
    {
      property: 'og:description',
      content: metadata.description,
    },
    {
      property: 'og:locale',
      content: metadata.locale === 'de' ? 'de_DE' : 'en_US',
    },
    {
      property: 'og:locale:alternate',
      content: metadata.locale === 'de' ? 'en_US' : 'de_DE',
    },
    {
      property: 'og:site_name',
      content: 'Science & Leadership',
    },
    {
      property: 'og:url',
      content: `https://science-and-leadership-web.netlify.app${metadata.pathname}`,
    },
    {
      property: 'og:image',
      content: metadata.image ? metaImages[metadata.image] : metaImages.default,
    },
  ]

  const otherMetaTags = [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, viewport-fit=cover',
    },
    { name: 'description', content: metadata.description },
    { name: 'author', content: 'Karolien Notebaert' },
  ]

  const linkTags = [
    // {
    //   rel: 'alternate',
    //   href: pageURL,
    //   hreflang: metadata.locale === 'de' ? 'de-de' : 'en-us',
    // },
    // {
    //   rel: 'alternate',
    //   href: `https://www.example.com${alternatePageURL}`,
    //   hreflang: metadata.locale === 'de' ? 'en-us' : 'de-de',
    // },
    // {
    //   rel: 'canonical',
    //   href: `${metadata.url}/`,
    // },
  ]

  return (
    <Helmet
      title={metadata.title}
      defaultTitle="Science & Leadership"
      titleTemplate="%s — Science & Leadership"
      meta={[...otherMetaTags, ...twitter, ...openGraph]}
      link={linkTags}
    >
      <html lang="en" />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SEO.propTypes = {
  metadata: PropTypes.shape({
    /** Title tag */
    title: PropTypes.string.isRequired,
    /** Description tag */
    description: PropTypes.string.isRequired,
    /** Meta image to use for the respective page */
    image: PropTypes.oneOf(['default']),
    /** Locale */
    locale: PropTypes.oneOf(['en', 'de']),
    /** URL of the respective page */
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.any,
      name: PropTypes.any,
      url: PropTypes.any,
    })
  ),
  article: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    datePublished: PropTypes.string.isRequired,
    dateModified: PropTypes.string.isRequired,
  }),
}

SEO.defaultProps = {
  breadcrumbs: [],
  article: null,
}

export { SEO }

// const query = graphql`
//   query SEO {
//     site {
//       buildTime(formatString: "YYYY-MM-DD")
//     }
//   }
// `
