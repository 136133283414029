import styled, { themeGet } from '@style'

const List = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: ${themeGet('space.4')};
  list-style: none;
  font-weight: ${themeGet('fontWeights.normal')};
`

const Item = styled.li`
  position: relative;
  margin: 0;
  line-height: 1.4;
  color: ${themeGet('colors.grey.900')};
  font-size: ${themeGet('fontSizes.1')};
  font-family: ${themeGet('fonts.sans')};
  font-weight: inherit;

  a {
    text-decoration: none;
    font-style: normal;
    position: relative;
    color: ${themeGet('colors.blue.700')};
    font-weight: ${themeGet('fontWeights.medium')};
    background-image: ${themeGet('colors.link.backgroundColor')};
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% 0.5em;
  }

  /* stylelint-disable */
  a:focus,
  a:hover {
    color: ${themeGet('colors.blue.100')};
    background-image: ${themeGet('colors.link.hoverBackgroundColor')};
    background-size: 100% 1.2em;
  }
  /* stylelint-enable */

  & + & {
    margin-top: ${themeGet('space.4')};
  }

  &::before {
    content: '';
    height: 8px;
    width: 8px;
    transform: translate3d(-${themeGet('space.4')}, 0.35em, 0);
    background: ${themeGet('colors.grey.300')};
    position: absolute;
    left: 0;
    top: 0.08em;
    border-radius: 52% 48% 64% 36% / 50% 57% 43% 50%;
  }

  &:nth-child(2n)::before {
    border-radius: 47% 53% 45% 55% / 54% 48% 52% 46%;
  }

  &:nth-child(3n)::before {
    border-radius: 50% 50% 49% 51% / 59% 35% 65% 41%;
  }
`

export { List, Item }
