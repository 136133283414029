import React from 'react'

import { Box, Flex } from '@components/Grid'
import { Title } from './Title'
import { Link, OutboundLink } from './Link'
import { Logo } from './Logo'

const Footer = () => (
  <Box
    as="footer"
    css={{
      background:
        'linear-gradient( 190deg, hsl(217, 33%, 22%), hsl(217, 38%, 15%))',
    }}
    p={[6]}
  >
    <Flex flexDirection={['column', null, 'row']} maxWidth="900px" mx="auto">
      <Flex
        flexDirection={['column']}
        justifyContent="flex-start"
        width={['100%', null, '35%']}
        mb={[6]}
      >
        <Box as={Link} to="/" width={['180px']}>
          <Logo />
        </Box>
        <Box>
          <Flex flexDirection="column">
            <Flex>
              <Box as="span" mr={3} color="blue.500">
                <svg
                  width={19}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </Box>
              <OutboundLink to="https://goo.gl/maps/MFbK7gk19Zh8cApi8">
                Frankfurt, Germany
              </OutboundLink>
            </Flex>
            <Flex>
              <Box as="span" mr={3} color="blue.500">
                <svg
                  width={19}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </Box>
              <OutboundLink to="tel:004915123569734">
                +49 151 23569734
              </OutboundLink>
            </Flex>
            <Flex>
              <Box as="span" mr={3} color="blue.500">
                <svg
                  width={19}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </Box>
              <OutboundLink to="mailto:info@science-and-leadership.com">
                info@science-and-leadership.com
              </OutboundLink>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Flex
        flexDirection={['row', 'row']}
        flexWrap={['wrap', 'nowrap']}
        width={['100%', null, '65%']}
        pt={[0, null, 5]}
        pl={[0, null, 7]}
      >
        <Flex
          flexDirection="column"
          width={['50%', '1/4']}
          pr={[0, 3]}
          mb={[6, null, 0]}
        >
          <Title>Company</Title>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/people">People</Link>
        </Flex>
        <Flex
          flexDirection="column"
          width={['50%', '1/4']}
          px={[0, 3]}
          mb={[6, null, 0]}
        >
          <Title>Expertise</Title>
          <Link to="/expertise/keynotes">Keynotes</Link>
          <Link to="/expertise/trainings">Trainings</Link>
          <Link to="/expertise/retreats">Retreats</Link>
        </Flex>
        <Flex
          flexDirection="column"
          width={['50%', '1/4']}
          pl={[0, 3]}
          mb={[0]}
        >
          <Title>Legal</Title>
          <Link to="/imprint">Imprint</Link>
          <Link to="/privacy">Privacy</Link>
        </Flex>
        <Flex
          flexDirection="column"
          width={['50%', '1/4']}
          pl={[0, 3]}
          mb={[0]}
        >
          <Title>Social</Title>
          <OutboundLink to="https://www.linkedin.com/in/karolien-notebaert-a7510b8">
            LinkedIn
          </OutboundLink>
          <OutboundLink to="https://twitter.com/karonotebaert">
            Twitter
          </OutboundLink>
        </Flex>
      </Flex>
    </Flex>{' '}
  </Box>
)

export { Footer }
