import React from 'react'
import PropTypes from 'prop-types'
import GoogleAnalytics from 'react-ga'
import { useCookies } from 'react-cookie'

const COOKIE_NAME = 'cookie-consent'

const OutboundLink = ({ to, from, target, children, className }) => {
  const [cookies] = useCookies([COOKIE_NAME])

  return (
    <a
      href={to}
      target={target}
      className={className}
      rel="noopener"
      onClick={() => {
        if (cookies?.[COOKIE_NAME] === 'true') {
          GoogleAnalytics.event({
            category: 'Outbound Link',
            action: `[clicked] ${from}`,
            label: to,
          })
        }
      }}
    >
      {children}
    </a>
  )
}

OutboundLink.propTypes = {
  to: PropTypes.string.isRequired,
  from: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

OutboundLink.defaultProps = {
  target: ``,
  className: ``,
  from: `unknown`,
}

export default OutboundLink
