/**
 * A basic Button component.
 *
 * Use buttons to signal clickable actions, such as “download,” “sign up,” or “log out.”.
 * Don't use it for redirects to different pages, use links for this instead.
 * If you want a link to look like a button you can use styled-components `as` prop,
 * but make sure the rendered DOM element is an <a> tag.
 * See: https://www.styled-components.com/docs/api#as-polymorphic-prop
 */
import PropTypes from 'prop-types'
import styled, { space, width, fontSize } from '@style'

import { baseStyles } from './baseStyles'
import { primaryStyles } from './primaryStyles'
import { secondaryStyles } from './secondaryStyles'
import { Arrow } from './Arrow'

const variants = {
  primary: primaryStyles,
  secondary: secondaryStyles,
}

const Button = styled.button`
  ${baseStyles};
  ${props => (props.variant ? variants[props.variant] : null)}
  ${fontSize}
  ${width}
  ${space}
`

Button.defaultProps = {
  onClick: null,
  fullWidth: false,
  height: '50px',
  children: null,
  type: 'button',
  disabled: false,
  variant: 'primary',
}

Button.propTypes = {
  /* Click handler */
  onClick: PropTypes.func,
  /* Whether the Button should expand to full width */
  fullWidth: PropTypes.bool,
  /* Height of the Button */
  height: PropTypes.string,
  /* Content inside the Button */
  children: PropTypes.node,
  /* Button type, usually "button" or "submit" */
  type: PropTypes.string,
  /* Whether the Button should be clickable */
  disabled: PropTypes.bool,
  /** Style variant of the Button: Primary, Secondary, Tertiary */
  variant: PropTypes.oneOf(['primary', 'secondary']),
}

Button.Arrow = Arrow

export { Button }
