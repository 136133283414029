import { css, themeGet } from '@style'

const disabledStyles = css`
  background: ${themeGet('colors.button.primary.disabledBackgroundColor')};
  color: ${themeGet('colors.button.primary.disabledTextColor')};
  cursor: default;

  &:hover,
  &:focus {
    background: ${themeGet('colors.button.primary.disabledBackgroundColor')};
    color: ${themeGet('colors.button.primary.disabledTextColor')};
  }
`

const primaryStyles = css`
  min-height: ${props => (props.height ? props.height : '50px')};
  background: ${themeGet('colors.button.primary.backgroundColor')};
  color: ${themeGet('colors.button.primary.textColor')};
  /* stylelint-disable */
  box-shadow: 0 4px 6px rgba(93, 50, 50, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  /* stylelint-enable */
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: ${themeGet('radii.1')};
    /* stylelint-disable */
    box-shadow: 0 7px 14px rgba(93, 50, 50, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    /* stylelint-enable */
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover,
  &:focus {
    background: ${themeGet('colors.button.primary.hoverBackgroundColor')};
    color: ${themeGet('colors.button.primary.hoverTextColor')};
    transform: translate3d(0, -2px, 0);

    &::after {
      opacity: 1;
    }
  }

  ${props => (props.disabled ? disabledStyles : '')}
`

export { primaryStyles }
