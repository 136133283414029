import * as styledComponents from 'styled-components'
import propTypes from '@styled-system/prop-types'
import { themeGet } from '@styled-system/theme-get'
import sx from '@styled-system/css'
import { theme } from './theme'

const {
  default: styled,
  createGlobalStyle,
  keyframes,
  css,
  ThemeProvider,
} = styledComponents

export {
  css,
  sx,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  theme,
  themeGet,
  propTypes,
}
export * from 'styled-system'
export default styled
