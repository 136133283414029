/* eslint-disable prefer-destructuring */
/**
 * This is our custom theme where we define global styles.
 * It should serve as a guideline for styling, but not all styles *have* to be taken from here.
 */
const breakpoints = ['576px', '768px', '992px', '1200px']

//  Aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

/**
 * Primary: Colors to use for actionable items, such as links, buttons etc.
 * Grey: Colors for items that are not that important
 */
const brandColors = {
  pageBackground: 'hsl(228, 33%, 97%)',
  sectionBackground: {
    light: 'hsl(214, 44%, 97%)',
  },
  white: 'hsl(0, 0%, 100%)',
  black: 'hsl(0, 0%, 0%)',
  red: {
    dark: 'hsl(3, 77%, 41%)',
    base: 'hsl(3, 77%, 52%)',
    light: 'hsl(3, 100%, 60%)',
  },
  grey: {
    100: 'hsl(216, 26%, 96%)',
    200: 'hsl(216, 19%, 85%)',
    300: 'hsl(215, 16%, 73%)',
    400: 'hsl(216, 14%, 61%)',
    500: 'hsl(215, 11%, 50%)',
    600: 'hsl(213, 14%, 39%)',
    700: 'hsl(212, 17%, 29%)',
    800: 'hsl(211, 21%, 20%)',
    900: 'hsl(208, 24%, 11%)',
  },
  blue: {
    100: 'hsl(214, 100%, 97%)',
    200: 'hsl(216, 50%, 89%)',
    300: 'hsl(218, 37%, 80%)',
    400: 'hsl(218, 30%, 70%)',
    500: 'hsl(218, 25%, 60%)',
    600: 'hsl(218, 21%, 51%)',
    700: 'hsl(218, 24%, 41%)',
    800: 'hsl(218, 29%, 32%)',
    900: 'hsl(217, 33%, 22%)',
  },
  green: {
    100: 'hsl(142, 81%, 94%)',
    200: 'hsl(141, 67%, 80%)',
    300: 'hsl(145, 65%, 68%)',
    400: 'hsl(146, 57%, 65%)',
    500: 'hsl(145, 55%, 49%)',
    600: 'hsl(145, 55%, 41%)',
    700: 'hsl(145, 59%, 33%)',
    800: 'hsl(155, 63%, 26%)',
    900: 'hsl(156, 61%, 20%)',
  },
}

const componentColors = {
  header: {
    backgroundColor: brandColors.white,
    desktopMenu: {
      textColor: brandColors.grey[700],
      textColorHover: brandColors.grey[900],
      activeIndicatorColor: brandColors.red.base,
    },
  },
  button: {
    primary: {
      textColor: brandColors.white,
      backgroundColor: brandColors.red.base,
      hoverTextColor: brandColors.white,
      hoverBackgroundColor: brandColors.red.light,
      disabledTextColor: brandColors.grey[100],
      disabledBackgroundColor: brandColors.grey[300],
    },
    secondary: {
      textColor: brandColors.blue[700],
      backgroundColor: brandColors.blue[100],
      borderColor: brandColors.blue[200],
      hoverBorderColor: brandColors.blue[400],
      hoverTextColor: brandColors.blue[900],
      hoverBackgroundColor: brandColors.blue[100],
      disabledTextColor: brandColors.grey[100],
      disabledBackgroundColor: brandColors.grey[300],
    },
  },
  em: {
    backgroundColor: `linear-gradient(90deg, ${brandColors.blue[300]}, ${brandColors.blue[200]})`,
  },
  link: {
    backgroundColor: `linear-gradient(90deg, ${brandColors.blue[200]}, ${brandColors.blue[200]})`,
    hoverBackgroundColor: `linear-gradient(90deg, ${brandColors.blue[800]}, ${brandColors.blue[800]})`,
  },
  newsletterSection: {
    background: `linear-gradient(80deg, ${brandColors.blue[300]}, ${brandColors.blue[200]})`,
  },
  form: {
    common: {
      default: {
        inputTextColor: brandColors.grey[800],
        inputPlaceholderColor: brandColors.grey[700],
        inputBackgroundColor: 'hsla(255, 100%, 100%, 0.6)',
        inputBorderColor: brandColors.blue[400],
        labelTextColor: brandColors.grey[800],
      },
      focused: {
        inputBackgroundColor: 'hsla(255, 100%, 100%, 0.8)',
        inputBorderColor: brandColors.blue[600],
      },
    },
  },
}

const colors = {
  ...brandColors,
  ...componentColors,
}

/**
 * Space is used for margin and padding scales.
 * It's recommended to use powers of two to ensure alignment across the entire project
 */
const space = [
  '0',
  '4px',
  '8px',
  '12px',
  '16px',
  '24px',
  '32px',
  '48px',
  '64px',
  '96px',
  '128px',
  '192px',
  '256px',
  '384px',
  '512px',
  '640px',
  '768px',
]

/**
 * Typographic scale based on "Major Third" and base size of 17px
 *
 * See: https://type-scale.com/
 */
const fontSizes = [
  '13.60px',
  '17.00px',
  '21.25px',
  '26.56px',
  '33.20px',
  '41.50px',
  '51.88px',
]

const lineHeights = [1, 1.125, 1.25, 1.5]

const fontWeights = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

const fonts = {
  serif: 'IBM Plex Serif',
  sans: 'IBM Plex Sans',
}

/**
 * Letter-spacing should vary, depending on usage of text
 */
const letterSpacings = {
  normal: 'normal',
  caps: '0.25em',
  labels: '0.05em',
}

/**
 * Border-radius
 */
const radii = ['0', '6px']

/**
 * Box-shadow
 */
const shadows = {
  subtle: `0 1.1px 2.7px -3px rgba(0, 0, 0, 0.034),
  0 2.9px 6.9px -3px rgba(0, 0, 0, 0.049),
  0 5.8px 14.2px -3px rgba(0, 0, 0, 0.061),
  0 12px 29.2px -3px rgba(0, 0, 0, 0.076),
  0 33px 80px -3px rgba(0, 0, 0, 0.11)`,
}

export const theme = {
  name: 'Default',
  breakpoints,
  colors,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  fonts,
  radii,
  shadows,
}
