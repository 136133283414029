import styled from '@style'

import { BaseH1, BaseH2, BaseH3, BaseH4 } from '@components/Heading'
import { BaseText } from './Text'

const BlockTextContainer = styled.div`
  /* stylelint-disable */
  ${BaseH1},
  ${BaseH2},
  ${BaseH3},
  ${BaseH4},
  ${BaseText} {
    margin-bottom: 1.45rem;
  }
  /* stylelint-enable */
`

export { BlockTextContainer }
