/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { Box, Flex } from '@components/Grid'
import { Text } from '@components/Text'
import { Button } from '@components/Button'
import { Link } from '@components/Link'

const PureCookieBanner = ({
  show,
  onAccept,
  onDecline,
  content: { text, link, acceptButton, declineButton },
}) => {
  if (!show) {
    return null
  }

  return (
    <Box
      style={{
        position: 'fixed',
        zIndex: '900',
        bottom: 0,
        left: 0,
        right: 0,
        paddingBottom: '16px',
        paddingRight: '16px',
        paddingLeft: '16px',
      }}
    >
      <Box
        maxWidth="700px"
        mx="auto"
        bg="grey.100"
        borderRadius={4}
        p={5}
        style={{
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Flex
          flexDirection={['column', null, 'row']}
          alignItems={[null, null, 'center']}
        >
          <Box flex={['100%', null, '50%']} mb={[4, null, 0]}>
            <Text color="grey.700" fontSize={0} pr={4}>
              {text}
              <Link to="/privacy">{link}</Link>
            </Text>
          </Box>
          <Flex
            flex={['1 1 100%', null, '0 0 auto']}
            flexDirection={['column', null, 'row']}
            alignItems={[null, null, 'center']}
          >
            <Box>
              <Button
                width={['100%', null, 'unset']}
                variant="secondary"
                onClick={onDecline}
                mb={[4, null, 0]}
                mr={[0, null, 4]}
                css={{ minHeight: '50px' }}
              >
                {declineButton}
              </Button>
            </Box>
            <Box>
              <Button width={['100%', null, 'unset']} onClick={onAccept}>
                {acceptButton}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

const CookieBanner = ({ show, onAccept, onDecline }) => {
  const { content } = useStaticQuery(
    graphql`
      query CookieBannerQuery {
        content: sanityCookieBannerSingleton {
          text
          link
          acceptButton
          declineButton
        }
      }
    `
  )
  return (
    <PureCookieBanner
      content={content}
      show={show}
      onAccept={onAccept}
      onDecline={onDecline}
    />
  )
}

CookieBanner.propTypes = {
  show: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
}

export { CookieBanner }
