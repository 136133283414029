import React, { useState } from 'react'

import { Flex, Box } from '@components/Grid'
import { Button } from '@components/Button'
import { Logo } from '@components/Logo'
import { Link } from '@components/Link'
import { sx } from '@style'

import { DesktopMenu } from './DesktopMenu'
import { MobileMenu } from './MobileMenu'

import { Header as HeaderContainer } from './Header'
import MenuIcon from './MenuIcon'

const Header = () => {
  const [isMenuVisible, setMenuVisible] = useState(false)

  const handleToggle = () => {
    if (isMenuVisible) {
      setMenuVisible(false)
    } else {
      setMenuVisible(true)
    }
  }

  return (
    <HeaderContainer>
      <Box
        bg="header.backgroundColor"
        px={4}
        css={sx({
          borderBottom: '1px solid',
          borderColor: 'grey.100',
        })}
      >
        <Box maxWidth="1000px" m="0 auto">
          <Flex
            height={['70px', null, '90px']}
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Flex p={2} flex={['0 1 120px', '0 1 160px']} mr="auto">
              <Link to="/" css={{ display: 'inline-flex' }}>
                <Logo />
              </Link>
            </Flex>
            <Flex
              display={['none', null, null, 'flex']}
              flex="1 1 auto"
              justifyContent="flex-end"
              height="100%"
            >
              <DesktopMenu />
            </Flex>
            <Flex ml={[4, 5]}>
              <Button
                as="a"
                href="mailto:info@science-and-leadership.com"
                px={[4, 6]}
                variant="secondary"
              >
                Contact
              </Button>
            </Flex>
            <Flex
              display={['flex', null, null, 'none']}
              justifyContent="center"
              alignItems="center"
              mr={-4}
              ml={4}
            >
              <Flex
                position="relative"
                justifyContent="center"
                alignItems="center"
                p={5}
                pr={6}
                onClick={handleToggle}
              >
                <MenuIcon active={isMenuVisible} />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
      {isMenuVisible && <MobileMenu />}
    </HeaderContainer>
  )
}

export { Header }
