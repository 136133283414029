import React from 'react'
import styled, { themeGet } from '@style'

const BaseSVG = props => (
  <svg width={31} height={16} viewBox="0 0 31 16" fill="none" {...props}>
    <path
      d="M30.707 8.707a1 1 0 000-1.414L24.343.929a1 1 0 10-1.414 1.414L28.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h30V7H0v2z"
      fill="inherit"
    />
  </svg>
)

const Arrow = styled(BaseSVG)`
  fill: currentColor;
  margin-left: ${themeGet('space.3')};
`

export { Arrow }
