import styled, { themeGet, space } from '@style'

const Title = styled.h6`
  color: ${themeGet('colors.blue.400')};
  font-size: ${themeGet('fontSizes.0')};
  font-weight: ${themeGet('fontWeights.medium')};
  font-family: ${themeGet('fonts.sans')};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${themeGet('space.4')};
  ${space}
`

export { Title }
