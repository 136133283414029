import React from 'react'
import { useSpring, useTrail, animated } from 'react-spring'

import { Flex } from '@components/Grid'

import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll'

import { MenuItem, Chevron } from './MenuItem'
import { MenuSubItem } from './MenuSubItem'

const menu = [
  { label: 'Home', to: '/' },
  { label: 'About', to: '/about' },
  { label: 'People', to: '/people' },
  { label: 'Expertise', to: '/expertise' },
  { label: 'Keynotes', to: '/expertise/keynotes', subitem: true },
  { label: 'Leadership Trainings', to: '/expertise/trainings', subitem: true },
  { label: 'Leadership Retreats', to: '/expertise/retreats', subitem: true },
  { label: 'Books', to: '/books/pilgrim-who-trained-her-monkey' },
  {
    label: 'The Pilgrim Who Trained Her Monkey',
    to: '/books/pilgrim-who-trained-her-monkey',
    subitem: true,
  },
]

const MobileMenu = () => {
  useLockBodyScroll()

  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const trail = useTrail(menu.length, {
    from: { opacity: 0, trnsfrm: 60 },
    to: { opacity: 1, trnsfrm: 0 },
    config: { mass: 5, tension: 2000, friction: 200 },
    delay: 50,
  })

  return (
    <Flex
      as={animated.div}
      position="fixed"
      zIndex="100"
      flexDirection="column"
      css={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
        background:
          'linear-gradient( 190deg, hsl(217, 33%, 22%), hsl(217, 38%, 15%))',
      }}
      style={{
        top: '70px',
        right: '0',
        opacity: spring.opacity,
      }}
    >
      {trail.map(({ opacity, trnsfrm, ...rest }, index) => (
        <animated.div
          key={menu[index].label}
          style={{
            display: 'flex',
            opacity,
            transform: trnsfrm.to(x => `translateX(${x}px)`),
            ...rest,
          }}
        >
          {menu[index].subitem ? (
            <MenuSubItem
              to={menu[index].to}
              activeClassName="mobile-menu--active"
            >
              {menu[index].label}
            </MenuSubItem>
          ) : (
            <MenuItem to={menu[index].to} activeClassName="mobile-menu--active">
              {menu[index].label}
              {menu[index].label === 'Expertise' ||
              menu[index].label === 'Books' ? (
                <Chevron ml={1} />
              ) : null}
            </MenuItem>
          )}
        </animated.div>
      ))}
    </Flex>
  )
}

export { MobileMenu }
