import styled, {
  themeGet,
  space,
  color,
  layout,
  typography,
  propTypes,
} from '@style'

const Topline = styled.span`
  margin: 0;
  padding: 0;
  font-size: ${themeGet('fontSizes.0')};
  font-weight: ${themeGet('fontWeights.medium')};
  font-family: ${themeGet('fonts.sans')};
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  display: block;
  color: ${themeGet('colors.grey.800')};
  ${space}
  ${color}
  ${layout}
  ${typography}
`

Topline.displayName = `Topline`

Topline.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
}

export { Topline }
