import React from 'react'
import { Flex } from '@components/Grid'
import { Link } from '@components/Link'
import styled, { themeGet } from '@style'

const MenuItem = styled(Link)`
  display: flex;
  width: 100%;
  color: ${themeGet('colors.white')};
  font-size: ${themeGet('fontSizes.2')};
  font-family: ${themeGet('fonts.sans')};
  font-weight: ${themeGet('fontWeights.medium')};
  text-decoration: none;
  padding-top: ${themeGet('space.4')};
  padding-bottom: ${themeGet('space.4')};
  padding-left: ${themeGet('space.5')};
  padding-right: ${themeGet('space.5')};
  justify-content: flex-end;

  &.mobile-menu--active {
    font-style: normal;
    font-weight: inherit;
    position: relative;
    /* stylelint-disable */
    background-image: linear-gradient(
      90deg,
      ${themeGet('colors.blue.600')} 0%,
      ${themeGet('colors.blue.700')} 100%
    );
    /* stylelint-enable */
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 0.4em 100%;
  }
`

const Chevron = props => (
  <Flex {...props} display="inline-flex" ml={3}>
    <svg width={10} height={24} viewBox="0 0 58 35" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 .988c1.05 0 2.098.41 2.883 1.228l22.116 23 22.117-23a4 4 0 015.767 5.544l-25 26a4 4 0 01-5.767 0l-25-26A4 4 0 014 .988"
        fill="#717D8E"
      />
    </svg>
  </Flex>
)

export { MenuItem, Chevron }
