import React from 'react'
import PropTypes from 'prop-types'
import GoogleAnalytics from 'react-ga'
import { Link as GatsbyLink } from 'gatsby'
import { useCookies } from 'react-cookie'

const COOKIE_NAME = 'cookie-consent'

const Link = ({ to, from, children, className, ...props }) => {
  const [cookies] = useCookies([COOKIE_NAME])

  return (
    <GatsbyLink
      {...props}
      to={to}
      className={className}
      onClick={() => {
        if (cookies?.[COOKIE_NAME] === 'true') {
          GoogleAnalytics.event({
            category: 'Link',
            action: `[clicked] ${from}`,
            label: to,
          })
        }
      }}
    >
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  from: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Link.defaultProps = {
  className: ``,
  from: `unnamed link`,
}

export default Link
