import styled, { themeGet } from '@style'

const SubMenu = styled.div`
  background: ${themeGet('colors.header.backgroundColor')};
  display: flex;
  flex-direction: column;
  border-radius: ${themeGet('radii.1')};
  box-shadow: 0 7px 14px rgba(50, 50, 50, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid ${themeGet('colors.grey.100')};
  min-width: 240px;
`

export { SubMenu }
