import styled, {
  themeGet,
  space,
  color,
  layout,
  typography,
  position,
} from '@style'

const BaseText = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 1.75;
  ${space}
  ${color}
  ${layout}
  ${position}
  ${typography}

  strong {
    font-weight: ${themeGet('fontWeights.semibold')};
  }

  a {
    text-decoration: none;
    font-style: normal;
    position: relative;
    color: ${themeGet('colors.blue.700')};
    font-weight: ${themeGet('fontWeights.medium')};
    background-image: ${themeGet('colors.link.backgroundColor')};
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% 0.5em;
  }

  /* stylelint-disable */
  a:focus,
  a:hover {
    color: ${themeGet('colors.blue.100')};
    background-image: ${themeGet('colors.link.hoverBackgroundColor')};
    background-size: 100% 1.2em;
  }
  /* stylelint-enable */
`

export { BaseText }
