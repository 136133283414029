import React from 'react'
import PropTypes from 'prop-types'
import styled, { themeGet } from '@style'

const Wrapper = styled.a`
  position: absolute;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.icon > span:first-child {
    width: 22px;
    transition-delay: 400ms;
  }

  &.icon > span:nth-child(2) {
    width: 18px;
    transition-delay: 300ms;
  }

  &.icon > span:nth-child(3) {
    width: 11px;
    transition-delay: 200ms;
  }

  &.icon > span:nth-child(4) {
    transform: rotate(45deg) scaleX(0);
    position: absolute;
    top: 90%;
    right: 0;
    width: 25px;
    transition-delay: 100ms;
  }

  &.icon > span:nth-child(5) {
    transform: rotate(-45deg) scaleX(0);
    position: absolute;
    top: -10%;
    right: 0;
    width: 25px;
    transition-delay: 300ms;
  }

  &.icon.active > span:first-child {
    transform: scaleX(0);
    transition-delay: 0;
  }

  &.icon.active > span:nth-child(2) {
    transform: scaleX(0);
    transition-delay: 100ms;
  }

  &.icon.active > span:nth-child(3) {
    transform: scaleX(0);
    transition-delay: 200ms;
  }

  &.icon.active > span:nth-child(4) {
    transform: rotate(45deg) scaleX(1);
    transition-delay: 500ms;
  }

  &.icon.active > span:nth-child(5) {
    transform: rotate(-45deg) scaleX(1);
    transition-delay: 300ms;
  }
`

const Line = styled.span`
  display: block;
  clear: both;
  float: none;
  width: 16px;
  height: 2px;
  background: ${themeGet('colors.grey.900')};
  margin: 2px 0;
  transform: scaleX(1);
  transform-origin: 100% 50%;
  transition: all 200ms ease;
`

const MenuIcon = ({ active }) => {
  const className = active ? 'icon active' : 'icon'
  return (
    <Wrapper className={className}>
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
    </Wrapper>
  )
}

MenuIcon.propTypes = {
  active: PropTypes.bool.isRequired,
}

export default MenuIcon
