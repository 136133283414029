import {
  Link as BaseLink,
  OutboundLink as BaseOutboundLink,
} from '@components/Link'
import styled, { themeGet } from '@style'

const Link = styled(BaseLink)`
  color: ${themeGet('colors.blue.200')};
  text-decoration: none;
  margin-bottom: ${themeGet('space.5')};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const OutboundLink = styled(BaseOutboundLink)`
  color: ${themeGet('colors.blue.200')};
  text-decoration: none;
  margin-bottom: ${themeGet('space.5')};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export { Link, OutboundLink }
