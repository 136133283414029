import { Link } from '@components/Link'
import styled, { themeGet } from '@style'

const MenuSubItem = styled(Link)`
  display: flex;
  align-items: center;
  color: ${themeGet('colors.header.desktopMenu.textColor')};
  font-size: ${themeGet('fontSizes.1')};
  font-family: ${themeGet('fonts.sans')};
  font-weight: ${themeGet('fontWeights.medium')};
  text-decoration: none;
  padding-top: ${themeGet('space.4')};
  padding-bottom: ${themeGet('space.4')};
  padding-left: 28px;
  padding-right: ${themeGet('space.5')};
  line-height: 1.4;

  & + & {
    border-top: 1px solid ${themeGet('colors.grey.100')};
  }

  &:hover,
  &:focus {
    color: ${themeGet('colors.header.desktopMenu.textColorHover')};
    background: #f4f7fb;
  }
`

export { MenuSubItem }
