import { css, themeGet } from '@style'

const disabledStyles = css`
  background: ${themeGet('colors.button.secondary.disabledBackgroundColor')};
  color: ${themeGet('colors.button.secondary.disabledTextColor')};
  border-color: ${themeGet('colors.button.secondary.disabledTextColor')};
  cursor: default;

  &:hover,
  &:focus {
    background: ${themeGet('colors.button.secondary.disabledBackgroundColor')};
    color: ${themeGet('colors.button.secondary.disabledTextColor')};
    border-color: ${themeGet('colors.button.secondary.disabledTextColor')};
  }
`

const secondaryStyles = css`
  background: ${themeGet('colors.button.secondary.backgroundColor')};
  color: ${themeGet('colors.button.secondary.textColor')};
  /* stylelint-disable */
  box-shadow: inset 0 0 0 1px ${themeGet(`colors.button.secondary.borderColor`)};
  /* stylelint-enable */

  &:hover,
  &:focus {
    background: ${themeGet('colors.button.secondary.hoverBackgroundColor')};
    color: ${themeGet('colors.button.secondary.hoverTextColor')};
    /* stylelint-disable */
    box-shadow: inset 0 0 0 2px
      ${themeGet(`colors.button.secondary.borderColor`)};
    /* stylelint-enable */
  }

  ${props => (props.disabled ? disabledStyles : '')}
`

export { secondaryStyles }
