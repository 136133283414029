import { css, themeGet } from '@style'

const baseStyles = css`
  display: flex;
  width: ${props => props.fullWidth && '100%'};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: ${themeGet('space.6')};
  padding-right: ${themeGet('space.6')};
  padding-top: ${themeGet('space.3')};
  padding-bottom: ${themeGet('space.3')};
  font-size: ${themeGet('fontSizes.1')};
  font-weight: ${themeGet('fontWeights.medium')};
  font-family: ${themeGet('fonts.sans')};
  border-radius: ${themeGet('radii.1')};
  outline: 0;
  border: none;
  text-decoration: none !important;
  /* stylelint-disable */
  -webkit-user-select: none !important;
  -webkit-appearance: none !important;
  /* stylelint-enable */
`

export { baseStyles }
