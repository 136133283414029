import styled from '@style'

const OuterContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  background: hsl(0, 0%, 100%);
  overflow: hidden;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  /* stylelint-disable */
  box-shadow: 0 0px 2.2px rgba(0, 0, 0, 0.02), 0 0px 5.3px rgba(0, 0, 0, 0.028),
    0 0px 10px rgba(0, 0, 0, 0.035), 0 0px 17.9px rgba(0, 0, 0, 0.042),
    0 0px 33.4px rgba(0, 0, 0, 0.05), 0 0px 80px rgba(0, 0, 0, 0.07);
  /* stylelint-enable */
`

export { OuterContainer }
