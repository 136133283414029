import React from 'react'
import styled, { themeGet } from '@style'

const Strong = styled.strong`
  font-style: normal;
  font-weight: inherit;
  position: relative;
  background-image: ${themeGet('colors.em.backgroundColor')};
  background-repeat: no-repeat;
  background-position: 0.2em bottom;
  background-size: 100% 0.47em;
`

Strong.displayName = `Strong`

// eslint-disable-next-line react/prop-types
const strongSerializer = ({ children }) => <Strong>{children}</Strong>

const StrongDarkBackgroundWrapper = styled.div`
  strong {
    /* stylelint-disable */
    background-image: linear-gradient(
      90deg,
      ${themeGet('colors.blue.900')} 0%,
      ${themeGet('colors.blue.800')} 100%
    );
    /* stylelint-enable */
  }
`

export { Strong, strongSerializer, StrongDarkBackgroundWrapper }
