import React from 'react'
import styled, { themeGet } from '@style'

const Highlight = styled.span`
  font-style: normal;
  font-weight: inherit;
  position: relative;
  background-image: ${themeGet('colors.em.backgroundColor')};
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 0.5em;
`

Highlight.displayName = `Highlight`

// eslint-disable-next-line react/prop-types
const highlightSerializer = ({ children }) => <Highlight>{children}</Highlight>

export { Highlight, highlightSerializer }
