/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Link } from 'gatsby'
import { propTypes } from '@style'

import { H1, H2, H3, H4 } from '@components/Heading'
import { List, Item } from '@components/List'

import { BaseText } from './Text'
import { highlightSerializer } from './Highlight'
import { BlockTextContainer } from './BlockTextContainer'

const blockSerializer = ({ node, children, props }) => {
  const { style = 'normal' } = node

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    switch (level) {
      case '1':
        return <H1 {...props}>{children}</H1>
      case '2':
        return <H2 {...props}>{children}</H2>
      case '3':
        return <H3 {...props}>{children}</H3>
      case '4':
        return <H4 {...props}>{children}</H4>
      default:
        return <BaseText {...props}>{children}</BaseText>
    }
  }

  // Fall back to default handling
  return <BaseText {...props}>{children}</BaseText>
}

const getSerializers = props => {
  return {
    types: {
      block: node => blockSerializer({ ...node, props }),
    },
    marks: {
      highlight: highlightSerializer,
      link: ({ mark, children }) => {
        const { blank, href } = mark
        return blank ? (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        )
      },
      internalLink: ({ mark: { reference }, children }) => {
        if (!reference) {
          return <span>{children}</span>
        }

        const { slug = {} } = reference
        const href = `${slug.current}`
        return <Link to={href}>{children}</Link>
      },
    },
    list: ({ children }) => <List>{children}</List>,
    listItem: ({ children }) => <Item>{children}</Item>,
    container: BlockTextContainer,
  }
}

const Text = ({ content, ...props }) => {
  // If the `content` prop is passed, we need to parse the provided block content format
  if (content) {
    return (
      <BlockContent
        blocks={content}
        serializers={getSerializers(props)}
        renderContainerOnSingleChild
      />
    )
  }
  const { children } = props

  return <BaseText {...props}>{children}</BaseText>
}

Text.displayName = `Text`

Text.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.position,
  ...propTypes.typography,
}

export { BlockTextContainer } from './BlockTextContainer'
export { Text, BaseText }
