import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import styled, {
  themeGet,
  space,
  color,
  layout,
  typography,
  propTypes,
} from '@style'

import { strongSerializer } from './Strong'

const BaseH2 = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${themeGet('fontSizes.3')};
  font-weight: ${themeGet('fontWeights.semibold')};
  font-family: ${themeGet('fonts.serif')};
  line-height: 1.4;
  color: ${themeGet('colors.grey.800')};

  @media screen and (min-width: ${themeGet('breakpoints.sm')}) {
    font-size: ${themeGet('fontSizes.4')};
  }

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    font-size: ${themeGet('fontSizes.5')};
  }

  ${space}
  ${color}
  ${layout}
  ${typography}
`

// eslint-disable-next-line react/prop-types
const h2Serializer = ({ children, props }) => {
  return <BaseH2 {...props}>{children}</BaseH2>
}

const getSerializers = props => {
  return {
    types: {
      block: node => h2Serializer({ ...node, props }),
    },
    marks: {
      highlight: strongSerializer,
    },
  }
}

const H2 = ({ content, ...props }) => {
  // If the `content` prop is passed, we need to parse the provided block content format
  if (content) {
    return <BlockContent blocks={content} serializers={getSerializers(props)} />
  }
  const { children } = props

  return <BaseH2 {...props}>{children}</BaseH2>
}

H2.displayName = `H2`

H2.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
}

export { H2, BaseH2, h2Serializer }
