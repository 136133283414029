import { createGlobalStyle } from './index'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    word-break: break-word;
  }

  html {
    font-size: 17px;
  }

  html,
  body {
    min-height: 100%;
    height: 100%;
    min-width: 320px;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    background: hsl(0, 0%, 98%);
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  body.no-scroll {
    position: fixed;
    overflow: hidden;
  }

  form {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  /* stylelint-disable */
  [type=reset],
  [type=submit],
  button,
  html [type=button] {
    -webkit-appearance: none !important;
  }
  /* stylelint-enable */
`

export default GlobalStyles
