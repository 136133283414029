import React from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'

import { Header } from '@components/Header'
import { Footer } from '@components/Footer'
import { CookieBanner } from '@components/CookieBanner'
import GlobalStyles from '@style/GlobalStyles'
import { ThemeProvider, theme } from '@style'

import { OuterContainer } from './OuterContainer'
import { PageContainer } from './PageContainer'

// import 'resize-observer-polyfill'
import 'normalize.css'
import 'typeface-ibm-plex-sans'
import 'typeface-ibm-plex-serif'

const COOKIE_NAME = 'cookie-consent'

const Layout = ({ children }) => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME])

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <OuterContainer>
          <Header />
          <PageContainer>{children}</PageContainer>
          <Footer />
        </OuterContainer>
        <CookieBanner
          onAccept={() =>
            setCookie(COOKIE_NAME, true, {
              path: '/',
              maxAge: '31622400',
            })
          }
          onDecline={() =>
            setCookie(COOKIE_NAME, false, {
              path: '/',
              maxAge: '31622400',
            })
          }
          show={!cookies?.[COOKIE_NAME]}
        />
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
