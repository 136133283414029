import React from 'react'
import { Link } from '@components/Link'
import { Flex } from '@components/Grid'
import styled, { themeGet } from '@style'

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  color: ${themeGet('colors.header.desktopMenu.textColor')};
  font-size: ${themeGet('fontSizes.1')};
  font-family: ${themeGet('fonts.sans')};
  font-weight: ${themeGet('fontWeights.medium')};
  text-decoration: none;
  padding-top: ${themeGet('space.3')};
  padding-bottom: ${themeGet('space.3')};
  padding-left: ${themeGet('space.4')};
  padding-right: ${themeGet('space.4')};
  line-height: 1.6;

  &:hover,
  &:focus {
    color: ${themeGet('colors.header.desktopMenu.textColorHover')};
    background: #f4f7fb;
  }

  &.desktop-menu--active span {
    /* stylelint-disable */
    background-image: linear-gradient(
      90deg,
      ${themeGet('colors.blue.300')},
      ${themeGet('colors.blue.200')}
    );
    /* stylelint-enable */
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% 0.2em;
  }
`

const Chevron = props => (
  <Flex {...props}>
    <svg width={10} height={35} viewBox="0 0 58 35" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 .988c1.05 0 2.098.41 2.883 1.228l22.116 23 22.117-23a4 4 0 015.767 5.544l-25 26a4 4 0 01-5.767 0l-25-26A4 4 0 014 .988"
        fill="#717D8E"
      />
    </svg>
  </Flex>
)

export { MenuItem, Chevron }
