import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'

import { Flex } from '@components/Grid'

import { MenuItem, Chevron } from './MenuItem'
import { SubMenu } from './SubMenu'
import { MenuSubItem } from './MenuSubItem'

function debounce(func, wait = 100) {
  let timeout
  // eslint-disable-next-line func-names
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}

const DesktopMenu = () => {
  const [showExpertiseSubmenu, setShowExpertiseSubmenu] = useState(false)
  const expertiseSpring = useSpring({
    dspl: showExpertiseSubmenu ? 1 : 0,
    opacity: showExpertiseSubmenu ? 1 : 0,
    transform: `perspective(600px) translateY(${
      showExpertiseSubmenu ? 0 : 16
    }px)`,
    config: { mass: 1, tension: 400, friction: 40 },
  })

  const [showBooksSubmenu, setShowBooksSubmenu] = useState(false)
  const booksSpring = useSpring({
    dspl: showBooksSubmenu ? 1 : 0,
    opacity: showBooksSubmenu ? 1 : 0,
    transform: `perspective(600px) translateY(${showBooksSubmenu ? 0 : 16}px)`,
    config: { mass: 1, tension: 400, friction: 40 },
  })

  return (
    <Flex flexDirection="row" height="100%">
      <Flex>
        <MenuItem
          to="/"
          activeClassName="desktop-menu--active"
          className="desktop-menu-item"
        >
          <span>Home</span>
        </MenuItem>
        <MenuItem
          to="/about"
          activeClassName="desktop-menu--active"
          className="desktop-menu-item"
          partiallyActive
        >
          <span>About</span>
        </MenuItem>
        <MenuItem
          to="/people"
          activeClassName="desktop-menu--active"
          className="desktop-menu-item"
          partiallyActive
        >
          <span>People</span>
        </MenuItem>
      </Flex>
      <Flex
        position="relative"
        onMouseEnter={() => setShowExpertiseSubmenu(true)}
        onMouseLeave={debounce(() => setShowExpertiseSubmenu(false))}
      >
        <MenuItem
          to="/expertise"
          activeClassName="desktop-menu--active"
          className="desktop-menu-item"
          partiallyActive
        >
          <span>Expertise</span>
          <Chevron ml={1} />
        </MenuItem>
        <animated.div
          style={{
            position: 'absolute',
            top: 'calc(100% - 12px)',
            left: '-12px',
            zIndex: '100',
            opacity: expertiseSpring.opacity,
            transform: expertiseSpring.transform,
            display: expertiseSpring.dspl.to(d => (d === 0 ? 'none' : 'block')),
          }}
        >
          <SubMenu>
            <MenuSubItem to="/expertise/keynotes">Keynotes</MenuSubItem>
            <MenuSubItem to="/expertise/trainings">
              Leadership Trainings
            </MenuSubItem>
            <MenuSubItem to="/expertise/retreats">
              Leadership Retreats
            </MenuSubItem>
          </SubMenu>
        </animated.div>
      </Flex>
      <Flex
        position="relative"
        onMouseEnter={() => setShowBooksSubmenu(true)}
        onMouseLeave={debounce(() => setShowBooksSubmenu(false))}
      >
        <MenuItem
          to="/books/pilgrim-who-trained-her-monkey"
          activeClassName="desktop-menu--active"
          className="desktop-menu-item"
          partiallyActive
        >
          <span>Books</span>
          <Chevron ml={1} />
        </MenuItem>
        <animated.div
          style={{
            position: 'absolute',
            top: 'calc(100% - 12px)',
            left: '-12px',
            zIndex: '100',
            opacity: booksSpring.opacity,
            transform: booksSpring.transform,
            display: booksSpring.dspl.to(d => (d === 0 ? 'none' : 'block')),
          }}
        >
          <SubMenu>
            <MenuSubItem to="/books/pilgrim-who-trained-her-monkey">
              The Pilgrim Who Trained Her Monkey
            </MenuSubItem>
          </SubMenu>
        </animated.div>
      </Flex>
    </Flex>
  )
}

export { DesktopMenu }
